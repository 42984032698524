<template>
  <empty-layout>
    <div
      class="flex-wrapper-full-background d-flex fill-height"
      style="width: 100%"
    >
      <v-card
        class="mx-auto my-auto pa-5 dark--text"
        max-width="500"
        min-width="350"
        raised
        rounded="xl"
      >
        <v-card-title
          ><span class="font-weight-bold mx-auto">{{
            $t("scoliotracker.sign_in")
          }}</span></v-card-title
        >
        <v-card-text>
          <v-form>
            <v-row>
              <v-col>
                <custom-input
                  :error="errors.email"
                  :label="$t('scoliotracker.email')"
                  v-model="email"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <custom-input
                  :error="errors.password"
                  type="password"
                  :label="$t('scoliotracker.password')"
                  v-model="password"
                />
              </v-col>
            </v-row>
            <custom-button
              class="text-uppercase"
              style="width: 100%"
              @click="handleLogin"
              >{{ $t("scoliotracker.login") }}</custom-button
            >
          </v-form>
        </v-card-text>
        <v-card-actions class="text-center justify-space-around">
          <v-row class="align-center justify-center">
            <text-button to="forgot-password">
              {{ $t("scoliotracker.forgot_password") }}?
            </text-button>
            <span class="mx-1">|</span>
            <text-button :isActive="true" to="sign-up">
              {{ $t("scoliotracker.signup") }}
            </text-button>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </empty-layout>
</template>

<script>
export default {
  name: "Login",
  components: { EmptyLayout: () => import("../layouts/empty/Index.vue") },

  data: function () {
    return {
      email: "",
      password: "",
      errors: {},
    };
  },
  methods: {
    async handleLogin() {
      const { email, password } = this;
      this.$auth
        .login({ data: { email, password }, redirect: null })
        .then((res) => {
        //   let route =
        //     res.data.data.roles.findIndex((role) => role.name === "admin") > -1
        //       ? this.$auth.options.loginData.redirect.admin
        //       : this.$auth.options.loginData.redirect.other;
          let route = res.data.data.roles.includes('admin')
            ? this.$auth.options.loginData.redirect.admin
            : this.$auth.options.loginData.redirect.other;
          this.$router.push(route);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>


<style scoped lang="scss">

</style>
